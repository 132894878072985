<template>
  <div>
    <Base :title="title" titleIcon="el-icon-s-claim">
    <el-form ref="refForm" :model="form" label-width="180px" :rules="rules" :disabled="true">
      <el-tabs v-model="activeName" type="border-card" class="form-panel">
        <el-tab-pane label="国保计划书" name="second">
          <el-form-item label="项目名称" prop="project_Name">
            <el-input v-model="form.project_Name" placeholder="请输入项目"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="24">
              <el-space :size="10" style="vertical-align: top">
                <el-form-item label="全国重点文物保护单位" prop="heritageUnit_Id">
                  <SelectUnit :setting="selectUnitSetting" v-model="form.heritageUnit_Id" @change="selectUnitChange">
                  </SelectUnit>
                </el-form-item>
                <el-form-item label="级别">
                  {{
      dicLabel(
        currentHeritage.heritageLevel_Code,
        heritageLevelDic
      )
    }}
                </el-form-item>
                <el-form-item label="类型">
                  {{
        dicLabel(
          currentHeritage.heritageClass_ID,
          dicHeritageClass
        )
      }}
                </el-form-item>
              </el-space>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="批次" prop="heritageBatch_ID">
                <el-select v-model="form.heritageBatch_ID">
                  <el-option v-for="item in dicHeritageBatch" :key="item.id" :value="parseInt(item.value)"
                    :label="item.label"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否革命文物" prop="isRevolution">
                <el-switch v-model="form.isRevolution" active-text="是" inactive-text="否" />
              </el-form-item>
            </el-col>
          </el-row>




          <el-row>
            <el-col>
              <el-space>
                <el-form-item label="所在位置" prop="cityId">
                  <el-select v-model="form.cityId" placeholder="市级" clearable>
                    <el-option v-for="item in dicCity" :key="item.id" :value="item.id" :label="item.name"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="" prop="countryId" label-width="0">
                  <el-select v-model="form.countryId" placeholder="区县" clearable>
                    <el-option v-for="item in dicTown" :key="item.id" :value="item.id" :label="item.name"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="" prop="address" label-width="0">
                  <el-input v-model="form.address" placeholder="镇（街道）村" style="width: 400px"></el-input>
                </el-form-item>
              </el-space>
            </el-col>
          </el-row>

          <el-form-item>
            <el-alert title="资金相关数值单位均为万元，请填写实际数字，勿填写含其他文字描述" type="warning" show-icon />
          </el-form-item>

          <el-form-item label="类型" prop="isSafeProject">
            <div>
              <el-radio-group v-model="form.isSafeProject">
                <el-radio :label="0">保护工程</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="保护工程类型" prop="projectType">
            <el-radio-group v-model="form.projectType">
              <el-radio :label="item.value" v-for="item in scheduleTypeDic.filter(
      (p) => p.ext.projectType == form.isSafeProject
    )" :key="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="工程对象名称" prop="heritageUnitDesc">
            <el-input v-model="form.heritageUnitDesc" placeholder="（填写工程涉及文物名称及其具体位置、范围或结构单元）"></el-input>
          </el-form-item>


          <el-form-item label="工程总经费估算" prop="forecastAmount">
            <el-input v-model="form.forecastAmount" style="width:300px"></el-input> 万元
          </el-form-item>

          <el-form-item label="其他说明" prop="otherDesc">
            <el-input v-model="form.otherDesc" placeholder="" type="textarea" rows="5"></el-input>
          </el-form-item>


          <el-form-item label="项目计划申报单位" prop="projectWorkPlan">
            <el-input v-model="form.projectWorkPlan" placeholder="" rows="5"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系人" prop="contactPerson">
                <el-input v-model="form.contactPerson" placeholder="" rows="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电话" prop="contactPhone">
                <el-input v-model="form.contactPhone" placeholder="" rows="5"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="项目计划书" prop="setupFile">
            <FileuploadListV1 v-model:files="form.attachList" fileGroup="setupFile"
              accpet=".pdf,.wps,.ofd,.pdf,.doc,.docx" urlKey="filePath" nameKey="fileName"
              @upload="() => refForm.clearValidate('setupFile')">
            </FileuploadListV1>
          </el-form-item>


          <el-form-item label="批复文件" prop="setupDocId">
            <OffcialDocSelectAndCreate :category="4" v-model="form.setupDocId"></OffcialDocSelectAndCreate>
          </el-form-item>

          <el-form-item label="项目方案" prop="planFile">
            <FileuploadListV1 v-model:files="form.attachList" fileGroup="planFile"
              accpet=".pdf,.wps,.ofd,.pdf,.doc,.docx" urlKey="filePath" nameKey="fileName"
              @upload="() => refForm.clearValidate('planFile')">
            </FileuploadListV1>
          </el-form-item>

          <el-form-item label="工程对象照片及修缮工程图纸" prop="photoes">
            <FileuploadListV1 v-model:files="form.attachList" fileGroup="photoes"
              accpet=".pdf,.wps,.ofd,.pdf,.doc,.docx" urlKey="filePath" nameKey="fileName"
              @upload="() => refForm.clearValidate('photoes')">
            </FileuploadListV1>
          </el-form-item>

          <el-form-item label="相关附件" prop="attachList">
            <div>(可根据实际情况另附文字说明、图纸等其他材料)</div>

            <FileuploadList :setting="fileuploadSetting" @upload="() => refForm.clearValidate('attachList')">
            </FileuploadList>
          </el-form-item>

          <el-form-item label="填报时间">{{
      parseTime(form.writeDate, "{yyyy}年{mm}月{dd}日")
    }}</el-form-item>

        </el-tab-pane>

        <el-tab-pane label="审核" name="revivew"
          v-if="(form.status == 1 && user.inRoles(roles.city)) || (form.status == 2 && user.inRoles(roles.prov))">
          <Review @submit="onReviewSubmit">

          </Review>
        </el-tab-pane>
        <el-tab-pane label="操作记录" name="logs" :disabled="!logs || logs.length == 0">
          <LogHistory :data="logs"></LogHistory>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    </Base>

    <div class="pageaa-footer">
      <div class="button">
        <el-button icon="el-icon-back" @click="$router.push('/ReportAndReview/NationSetup')">返回</el-button>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import OffcialDocSelectAndCreate from "@/views/officialDoc/offcialDocSelectAndCreate.vue";
import FileuploadListV1 from "@/components/fileuploadListV1.vue";
import FileuploadList from "@/components/fileuploadList.vue";
import { useCityRelTown } from "@/network/chinaArea";
import { parseTime } from "@/utils/index.js";
import {
  dicLabel,
  useSelectItemList,
  useSelectItemWithChildList,
  scheduleTypeDic,
} from "@/network/lookUp.ts";
import SelectUnit from "@/views/heritageUnit/selectUnit.vue";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRef,
  inject,
} from "vue";
import Base from "../../layout/Base.vue";
import { useSetupProject, useQueryActionHistory } from "@/network/setupProject";
import roles from "@/types/roles.ts";
import { useFindHeritage } from "@/network/heritageUnit";
import { useRouter } from "vue-router";
import Review from "@/views/shared/review.vue";
import LogHistory from "@/views/shared/logHistory.vue";
export default defineComponent({
  name: "projectCreate",
  components: { Base, SelectUnit, FileuploadList, FileuploadListV1, Review, LogHistory, OffcialDocSelectAndCreate },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    // const form: any = reactive({
    //   code: "待编号",
    //   project_Name: "",
    //   historyProjects: [],
    //   writeDate: new Date(),
    //   attachList: [],
    //   safeProjectType: []
    // });
    const router = useRouter();
    const user: any = inject("user");
    const refForm = ref(null);

    const id: any = ref(props.id);
    const isFileUploading = ref(false);
    const [isLoading, form, find, save, , review] = useSetupProject(id);
    const [logs, queryHistory] = useQueryActionHistory(id);
    const [, , findHeritage] = useFindHeritage();

    const fileuploadSetting = reactive({
      accpet: ".pdf,.jpg,.bmp,.gif,.tif,.zip,.rar,.xls,.xlsx,.doc,.docx",
      urlKey: "filePath",
      nameKey: "fileName",
      isFileUploading,
      typeGroup: ref({
        list: form.attachList,
        type: "附件",
      }),
    });

    const selectUnitSetting = reactive({
      visible: false,
      disabled: false,
      heritage_Name: "",
    });
    const [dicCity, dicTown] = useCityRelTown(
      toRef(form, "cityId"),
      toRef(form, "countryId")
    );

    const heritageLevelDic = useSelectItemList("HeritageLevel");
    const dicHeritageBatch = useSelectItemList("HeritageBatch");
    const dicHeritageClass = useSelectItemList("HeritageClass");
    const [project_ClassifyDic, project_SubClassifyDic] =
      useSelectItemWithChildList(
        toRef(form, "project_Classify_Id"),
        toRef(form, "project_SubClassify_Id"),
        "ProjectClassify",
        false
      );

    const title = computed(() => {
      let str = currentHeritage.value.heritage_Name || "";
      str += ` ${dicLabel(form.projectType, scheduleTypeDic) || ""}计划书`;
      return `[${form.code}] ${str}  `;
    });
    const activeName = ref("second");

    const historyFunds = ref([]);
    const currentHeritage: any = ref({});
    const selectUnitChange = (item) => {
      currentHeritage.value = item;
      (form.heritageBatch_ID = item.heritageBatch_ID),
        (form.heritage_ManageOrg = item.heritage_ManageOrg);
      form.heritage_OwnerSuperior = item.heritage_OwnerSuperior;
      form.cityId = item.city_ID;
      form.countryId = item.county_ID;
    };

    const addHistoryProject = () => {
      form.historyProjects.push({});
    };

    const addHistoryFund = () => {
      form.historyFunds.push({});
    };

    const onReviewSubmit = (result) => {
      //console.log(result);
      review(form.id, result).then((p) => {
        emit("change");
        // emit("submit", result);
        router.push("/ReportAndReview/NationSetup");
      });
    };

    const doSave = (submit) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            if (submit) {
              if (user.inRoles(roles.city)) {
                form.status = 2;
              } else {
                form.status = 1;
              }
            } else if (form.status == null) {
              form.status = 0;
            }
            save().then(() => {
              if (id.value == 0) {
                router.replace({ params: { id: form.id || 0 } });
              }
              id.value = form.id;
              emit("change");
              if (submit) {
                emit("submit");
                router.push("/ReportAndReview/NationSetup");
              } else {
                fileuploadSetting.typeGroup.list = form.attachList;
                //queryHistory();
              }
              resolve("success");
            });
          } else {
            reject("error");
          }
        });
      });
    };

    const nextTo = () => {
      const errs = [];
      refForm.value.validateField(
        [
          "heritageUnit_Id",
          "heritageBatch_ID",
          "isRevolution",
          "isSafeProject",
          "safeProjectType",
          "projectType",
          "cityId",
          "countryId",
          "address",
          "heritage_ManageOrg",
          "heritage_OwnerSuperior",
        ],
        (valid) => {
          if (valid !== "") {
            errs.push(valid);
          }
        }
      );
      setTimeout(() => {
        if (errs.length == 0) {
          activeName.value = "second";
        }
      }, 1);
    };
    find().then((p) => {
      if (id.value && !p.id) {
        location.href = "/";
        return;
      }
      //console.log(user.id, form.createUser, user.inRoles(roles.review));
      if (form.status != null) {
        if (!user.inRoles(roles.review, roles.city) && user.id != form.createUser) {
          location.href = "/";
          return;
        }
      }
      //setTimeout(() => refForm.value.clearValidate(), 1);
      selectUnitSetting.heritage_Name = p.heritageUnitName;
      if (p.heritageUnit_Id) {
        findHeritage(p.heritageUnit_Id).then((res) => {
          currentHeritage.value = res;
        });
      }
      fileuploadSetting.typeGroup.list = form.attachList;

      //console.log(p);
      // console.log(form, p);
      queryHistory();
    });

    const rules = {
      heritageUnit_Id: [
        { required: true, message: "请输入文保单位！", trigger: "blur" },
      ],
      heritageBatch_ID: [
        { required: true, message: "请输入批次！", trigger: "change" },
      ],
      isRevolution: [
        { required: true, message: "请输入是否革命文物！", trigger: "change" },
      ],
      isSafeProject: [
        { required: true, message: "请输入工程类型！", trigger: "change" },
      ],
      safeProjectType: [
        { required: true, message: "请输入工程类型！", trigger: "change" },
      ],
      projectType: [
        { required: true, message: "请输入工程类型！", trigger: "change" },
      ],
      cityId: [{ required: true, message: "请输入市级！", trigger: "change" }],
      countryId: [
        { required: true, message: "请输入区县！", trigger: "change" },
      ],
      address: [{ required: true, message: "请输入村镇！", trigger: "blur" }],
      heritage_ManageOrg: [
        { required: true, message: "请输入使用管理单位！", trigger: "blur" },
      ],
      heritage_OwnerSuperior: [
        { required: true, message: "请输入上级主管部门！", trigger: "blur" },
      ],
      project_Name: [
        { required: true, message: "请输入工程对象名称！", trigger: "blur" },
      ],
      heritageUnitDesc: [
        {
          required: true,
          message: "请输入文物保护单位简况！",
          trigger: "blur",
        },
      ],
      projectNeedReason: [
        { required: true, message: "工程实施必要性", trigger: "blur" },
      ],
      projectProtectArea: [
        { required: true, message: "请输入工程防护范围", trigger: "blur" },
      ],
      projectProtectObject: [
        { required: true, message: "请输入工程防护对象", trigger: "blur" },
      ],
      forecastAmount: [{ required: true, message: "请输入", trigger: "blur" }],
      forecastAmountReason: [
        { required: true, message: "请输入测算依据", trigger: "blur" },
      ],
      projectForecastDoThings: [
        { required: true, message: "请输入拟采取的具体措施", trigger: "blur" },
      ],
      projectBaseThingsInfo: [
        { required: true, message: "请输入原有设施简况", trigger: "blur" },
      ],
      projectDoCondition: [
        { required: true, message: "请输入项目实施保障条件", trigger: "blur" },
      ],
      isInProtectPlan: [
        {
          required: true,
          message: "请输入是否纳入保护规划",
          trigger: "change",
        },
      ],
      isDoArcheology: [
        {
          required: true,
          message: "请输入是否开展过考古工作",
          trigger: "change",
        },
      ],
      isDoResearch: [
        {
          required: true,
          message: "请输入是否开展过前期勘察或研究",
          trigger: "change",
        },
      ],
      areaDesc: [
        { required: true, message: "请输入工程范围及规模", trigger: "blur" },
      ],
      techLine: [
        { required: true, message: "请输入工程技术路线", trigger: "blur" },
      ],
      showIdear: [
        { required: true, message: "请输入展示内容策划构想", trigger: "blur" },
      ],
      planDoPeroid: [
        { required: true, message: "请输入拟实施周期", trigger: "blur" },
      ],
      planEndTime: [
        { required: true, message: "请输入计划竣工时间", trigger: "blur" },
      ],
      // planOpenTime: [
      //   { required: true, message: "请输入计划对外开放时间", trigger: "blur" },
      // ],
      resultReview: [
        {
          required: true,
          message: "请输入工程效果预测及文物影响评估",
          trigger: "blur",
        },
      ],
      landCondition: [
        { required: true, message: "请输入土地现状", trigger: "blur" },
      ],
      landOwnType: [
        { required: true, message: "请输入土地权属", trigger: "change" },
      ],
      landManager: [
        { required: true, message: "请输入实际管理部门", trigger: "blur" },
      ],

      landPropType: [
        { required: true, message: "请输入土地性质", trigger: "change" },
      ],
      landPropMemo: [
        { required: true, message: "请输入土地性质备注", trigger: "blur" },
      ],

      forecastBeginAmount: [
        { required: true, message: "请输入前期经费（万元）", trigger: "blur" },
      ],
      forecastDoAmount: [
        {
          required: true,
          message: "请输入工程实施费（万元）",
          trigger: "blur",
        },
      ],
      sourceLocalAmount: [
        {
          required: true,
          message: "请输入地方财政经费（万元）",
          trigger: "blur",
        },
      ],
      sourceCenterAmount: [
        { required: true, message: "请输入国家省级（万元）", trigger: "blur" },
      ],
      sourceElseAmount: [
        { required: true, message: "请输入其他（万元）", trigger: "blur" },
      ],
      otherDesc: [
        {
          required: true,
          message: "请输入其他需要说明的情况",
          trigger: "blur",
        },
      ],
      projectWorkContent: [
        { required: true, message: "请输入工程项目内容", trigger: "blur" },
      ],
      projectWorkPlan: [
        { required: true, message: "请输入工程项目计划", trigger: "blur" },
      ],
      forecastProvAmount: [
        {
          required: true,
          message: "请输入申请省级文物保护补助经费",
          trigger: "blur",
        },
      ],
      contactPerson: [
        {
          required: true,
          message: "请输入联系人及电话",
          trigger: "blur",
          validator: (rule: any, value: any, callback: any) => {
            if (
              !form.contactPerson ||
              !form.contactPhone ||
              form.contactPerson === "" ||
              form.contactPhone === ""
            ) {
              callback(new Error("请输入联系人及电话"));
            } else {
              callback();
            }
          },
        },
      ],
      attachList: [
        {
          required: true,
          message: "附件是必填项!",
          trigger: "blur",
          validator: (rule: any, value: any, callback: any) => {
            if (!form.attachList || form.attachList.length == 0) {
              callback(new Error("附件是必填项!"));
            } else {
              callback();
            }
          },
        },
      ],
    };

    return {
      isFileUploading,
      nextTo,
      rules,
      logs,
      onReviewSubmit,
      user,
      roles,
      isLoading,
      refForm,
      doSave,
      historyFunds,
      fileuploadSetting,
      parseTime,
      addHistoryProject,
      addHistoryFund,
      scheduleTypeDic,
      dicHeritageClass,
      dicHeritageBatch,
      dicCity,
      dicTown,
      currentHeritage,
      activeName,
      title,
      form,
      selectUnitChange,
      selectUnitSetting,
      dicLabel,
      heritageLevelDic,
      project_ClassifyDic,
      project_SubClassifyDic,
    };
  },
});
</script>

<style lang="scss" scoped></style>
